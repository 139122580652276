const items = [
    // { title: "All Things {Frontend}", img: "all-things-front-end-sm.jpg", desc: "'All Things {Frontend}' is an article scraper where users can add notes and save articles. Created with Node.js, MongoDB, Express, Handlebars, Sass, BEM, Gulp, and Axios.", tags: ["MongoDB", "Node", "Sass", "Express", "Sass", "UI/UX", "Mobile-First"], url: "https://all-things-fe.herokuapp.com/", repo: "https://github.com/acarlie/all_things_front-end" },
    { title: "Remember It", img: "remember-it-sm.jpg", desc: "'Remember It' is a memory game created with React.", tags: ["React"], url: "http://acarlie.com/remember_it/", repo: "https://github.com/acarlie/remember_it" },
    { title: "Angular To Do App", img: "angular-to-do-sm.jpg", desc: "To do list application created with Angular, SCSS, and BEM. Tasks are saved in local storage.", tags: ["Angular", "Sass", "BEM"], url: "http://acarlie.com/angular_to_do/", repo: "https://github.com/acarlie/angular_to_do" },
    { title: "Movie Comparison App", img: "movie-comp-sm.jpg", desc: "Movie comparison app created with AJAX, jQuery, Chart.js, and Materialize. Designed mobile first.", tags: ["jQuery", "UI/UX", "Materialize", "AJAX", "Mobile-First"], url: "https://acarlie.com/movie_comparison_app/", repo: "https://github.com/acarlie/movie_comparison_app" },
    { title: "Responsive Nav with jQuery Scroll-spy", img: "scrollspy-nav-sm.jpg", desc: "Mobile responsive nav with scroll-spy.", tags: ["CSS, jQuery"], url: "https://codepen.io/acarlie/pen/JjPKmmV", repo: "" },
    { title: "Giphy Search", img: "giphy-2-sm.jpg", desc: "'Giphy Search' was created using Giphy's API. Users can favorite gifs and copy gif embed links. Designed mobile first.", tags: ["jQuery", "AJAX", "Mobile-First", "UI/UX"], url: "https://acarlie.com/Giphy_Search/", repo: "https://github.com/acarlie/Giphy_Search" },
    { title: "Burger App", img: "burger-app-sm.jpg", desc: "CRUD app created with Node.js, Express, Handlebars, MySQL, jQuery, and Bootstrap.", tags: ["jQuery", "Bootstrap", "MySQL", "Node", "Express", "Handlebars"], url: "https://blooming-falls-67802.herokuapp.com/", repo: "https://github.com/acarlie/burger" },
    { title: "Bamazon", img: "bamazon.gif", desc: "Node.js and MySQL inventory management app with customer, manager, and supervisor views.", tags: ["Node", "MySQL"], url: "", repo: "https://github.com/acarlie/bamazon" },
    { title: "Google Books Search", img: "google-books-sm.jpg", desc: "'Google Books Search' searches the Google Books API for books and allows users to save books. 'Google Books Search' is a full-stack MERN (Mongo, Express, React, Node) app using the Antd React component library.", tags: ["MERN", "Node", "MongoDB", "React", "Express"], url: "https://books-search-mern.herokuapp.com/", repo: "https://github.com/acarlie/google_books_search" },
    { title: "Color Me ____.", img: "color-me-4-sm.jpg", desc: "'Color Me ___' is a desktop word guessing game created with Vanilla JS.", tags: ["Vanilla JS", "UI/UX"], url: "https://acarlie.com/Color_Me", repo: "https://github.com/acarlie/Color_Me" },
    { title: "Vanilla JS Typewriter", img: "typewriter-sm.jpg", desc: "Vanilla JS typerwiter created using setInterval, clearInterval, setTimeout, and ES6 classes.", tags: ["Vanilla JS"], url: "https://codepen.io/acarlie/pen/KOqmPN", repo: "" },
    { title: "Women of Scifi and Fantasy Trivia", img: "trivia-game-sm.jpg", desc: "Timed trivia game created with jQuery and JavaScript.", tags: ["jQuery"], url: "https://acarlie.com/Trivia_Game/", repo: "https://github.com/acarlie/Trivia_Game" },
    { title: "Liri Node App", img: "liri_spotify-this-sm.png", desc: "Node CLI App for searching OMDB, Spotify, and Bands in Town. Created with ES6, Node, Axios, Inquirer.js, and Moment.js.", tags: ["ES6", "Node"], url: "", repo: "https://github.com/acarlie/liri_node_app" },
    { title: "Humun", img: "humun-sm.jpg", desc: "Humun is an app that empowers you to contribute in the most effective ways to the causes you care about, and makes it easier than ever to maintain and enhance your positive impact over time.", tags: ["React", "UI/UX", "MERN"], url: "https://humun-app.herokuapp.com/", repo: "https://github.com/acarlie/humun" },
    { title: "Train Scheduler", img: "train-schedule-sm.jpg", desc: "Train scheduling app created using jQuery, Firebase, and Materialize.", tags: ["jQuery", "Firebase", "Materialize"], url: "https://acarlie.com/Train_Scheduler/", repo: "https://github.com/acarlie/Train_Scheduler" },
    // { title: "Wags with Friends", img: "assets/images/portfolio/wags-with-friends-sm.jpg", desc: "App for finding playmates for pets. Created with SASS, MySQL, Sequelize, Node.js, Express, Express-Handlebars.", tags: ["MySQL, Sequelize, SASS"], url: "https://project2-10-2019.herokuapp.com/", repo: "https://github.com/acarlie/wags_with_friends" },
    // { title: "A Stranger Game", img: "a-stranger-game-2-sm.jpg", desc: "Player vs algorithm game created using jQuery and JavaScript.", tags: ["jQuery"], url: "https://acarlie.com/Stranger_Things_Game/", repo: "https://github.com/acarlie/Stranger_Things_Game" },
    // { title: "Star Trek Character Quiz", img: "star-trek-sm.jpg", desc: "'Star Trek Character Quiz' is a quiz app created with Node.js, Express, and the Semantic UI library.", tags: ["Node", "Express"], url: "https://intense-beyond-55341.herokuapp.com/", repo: "https://github.com/acarlie/star_trek" },
    // { title: "Wikipedia Search", img: "wikipedia-viewer-sm.jpg", desc: "Wikipedia search using jQuery, Wikipedia's API, SCSS, and Pug.", tags: ["jQuery", "Rest APIs", "UI/UX", "Sass", "Bootstrap"], url: "https://codepen.io/acarlie/pen/mERLej", repo: "" },
    { title: "CLI Word Guessing Game", img: "example.gif", desc: "Node.js word guessing game using JavaScript constructors.", tags: ["Node"], url: "", repo: "https://github.com/acarlie/Constructor_Word_Guess" }
];

export default items;